h1, h2, h3, h4, h5, h6 {
    font-family: 'Sanchez-Regular', serif;
    color: black;
}

h1 {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 1em;
}

h1.huge {
    font-size: 2em;
    line-height: 1.125;
}

h2 {
    font-size: 1.25rem;
    line-height: 1.125;
    margin-bottom: 1em;
}

h3 {
    font-size: 1.125rem;
    line-height: 1.2;
}

h4 {
    font-size: 1rem;
    line-height: 1.2;
}
h4.flat {
    line-height: 1;
}

h6 {
    font-size: 0.875em;
    line-height: 1.143;
}

.fade-enter, .fade-appear {
    opacity: 0.01;
}

.fade-enter.fade-enter-active, .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 280ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 280ms ease-out;
}
