.system-form {
  margin-bottom: 1em; }

.system-form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em;
  background-color: #e6e6e6;
  color: #000;
  line-height: 2em; }

.system-form-body {
  padding-top: 1em;
  padding-bottom: 1em;
  animation-name: systemFormFadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease; }

@keyframes systemFormFadeIn {
  from {
    opacity: 0; } }
