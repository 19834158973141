.sensor-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  padding: 2em;
  overflow: hidden;
  border-top: 2px solid #E6E6E6;
  background: #f2f2f2; }
  .sensor-actions > *:only-child {
    margin-left: auto; }
