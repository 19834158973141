.report-table {
  margin: 1em 0;
  width: 100%; }

.report-table-row {
  border-color: #E6E6E6;
  border-style: solid;
  border-width: 0 1px 1px; }
  .report-table-row:first-child {
    border-top-width: 1px; }

.report-table-heading {
  padding: 1em;
  font-weight: bold;
  background-color: #f2f2f2; }

.report-table-cell {
  padding: 1em;
  width: 50%; }
  .report-table-cell:first-child {
    font-size: 1.125em; }
  .report-table-cell:last-child {
    font-weight: bold;
    text-align: right; }
