.horizontal-navigation-bar {
  display: flex; }

.horizontal-navigation-item {
  display: flex;
  flex-grow: 1;
  border-right: 1px solid white; }

.horizontal-navigation-link {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #828282;
  background-color: #f2f2f2;
  text-align: center;
  padding: 1em 0.75em; }

.horizontal-navigation-link.active {
  background-color: white;
  color: #002f6c; }

.horizontal-navigation-link.active:before {
  position: absolute;
  content: '';
  top: -3px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: orange; }
