.document-list-item {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    padding: 1em;
    line-height: 1.5;
    background: #ffffff;
}

a.document-list-item {
    color: #002f6c;
}

.document-list-compact .document-list-item,
.document-list-item-compact.document-list-item {
    padding: 0.75em;
}

.document-list .document-list-item:nth-child(odd) {
    background: #f2f2f2;
}

.document-list-item:only-child {
    background: transparent !important;
}

.document-list-icon {
    margin-top: 0.5em;
    margin-right: 1.5em;
    text-align: center;
    transition: transform 0.3s ease;
    color: #75787B;
}

.document-list-compact .document-list-icon,
.document-list-item-compact .document-list-icon {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-right: 1.5em;
    margin-top: 0;
}

.document-list-icon .fa {
    font-size: 2em;
}

.document-list-compact .document-list-icon .fa,
.document-list-item-compact .document-list-icon .fa {
    font-size: 1.25em;
}

.document-list-icon-label {
    font-size: 0.75em;
    margin-top: 0.5em;
    text-align: center;
    min-width: 7em;
}

.document-list-compact .document-list-icon-label,
.document-list-item-compact .document-list-icon-label {
    line-height: 1;
    margin-top: 0.25em;
    width: 6em;
}

.document-list-body {
    flex: 1;
    margin-top: 0.25em;
    transition: transform 0.3s ease;
}

.document-list-body:first-child {
    margin-top: 0;
}

.document-list-compact .document-list-body,
.document-list-item-comapct .document-list-body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 0;
}

.document-list-title {
    font-weight: bold;
}

.document-list-compact .document-list-title,
.document-list-item-compact .document-list-title {
    font-size: 0.875em;
    line-height: 1;
    margin-bottom: 0.25em;

}

a.document-list-item:hover .document-list-title {
    text-decoration: underline;
}

.document-list-content {
    font-size: 0.75em;
    color: #75787B;
}