.chart text {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-rendering: optimizeLegibility; }

.chart-axis text {
  font-size: 12px;
  fill: #002D6E; }

.chart-axis path,
.chart-axis line {
  stroke: #E6E6E6;
  stroke-width: 1; }

.chart-bar-value {
  fill: #002D6E;
  font-weight: bold;
  font-size: 14px; }

.chart-legend text {
  font-size: 10px; }

.chart-legend-item text {
  transition: transform 0.3s ease-in-out, font-weight 0.3s ease-in-out; }

.chart-legend-item.active text {
  font-weight: bold;
  transform: translate(-5px, 0); }

@keyframes chartFadeIn {
  from {
    opacity: 0.2; } }
