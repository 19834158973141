.cost-calculation-subdiscipline {
  position: relative;
  padding-left: 1em;
  font-weight: bold; }
  .cost-calculation-subdiscipline::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    width: 6px;
    border-width: 6px 0;
    border-style: solid;
    border-color: transparent;
    border-left: 6px solid #000; }
  .cost-calculation-subdiscipline.is-open::before {
    border-width: 0 6px;
    border-color: transparent;
    border-top: 6px solid #000; }
