.sub-discipline-form--close {
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: flex-start; }

.sub-discipline-form--close-tooltip {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 1em;
  opacity: 0;
  transition: all 0.3s; }

.sub-discipline-form--close-tooltip:after {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  right: -16px;
  top: 50%;
  margin-top: -8px;
  border: 8px solid transparent;
  border-left-color: #ff8f1c; }

.sub-discipline-form--close-button:hover + .sub-discipline-form--close-tooltip {
  opacity: 1;
  transform: translateX(0.25em); }
