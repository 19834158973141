.section {
  margin: 3rem 0; }

.section-margin-large {
  margin: 5rem 0; }

.section-with-divider {
  border-bottom: 1px solid #e6e6e6; }

.section-subtitle {
  font-size: 1.25em;
  font-family: 'Sanchez-Regular', serif;
  font-weight: normal;
  color: #002D6E; }
