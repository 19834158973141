.steps {
  position: relative;
  z-index: 10; }

.steps-index {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 1em; }
  .steps-index:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    border: 2px solid #f2f2f2; }

.step-index {
  position: relative;
  z-index: 1;
  padding: 1em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  margin: 0.5em;
  border-radius: 50%;
  color: #73787D;
  line-height: 1;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  box-sizing: content-box;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.35s ease-in-out;
  transform: translateZ(0); }
  .step-index:hover {
    opacity: 1; }
  .step-index .step-inner {
    position: relative;
    z-index: 3; }
  .step-index:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 2;
    opacity: 0;
    background-color: #FF911E;
    transform: scale(0);
    transform-origin: center;
    transition: all 0.35s ease-in-out; }
  .step-index:hover::before {
    transform: scale(0.1);
    opacity: 0.1; }
  .step-index.active {
    color: #FFFFFF;
    transition-delay: 0.15s; }
    .step-index.active:before {
      transform: scale(1);
      opacity: 1;
      transition-delay: 0.15s; }

.steps-wrapper {
  margin: 2em 0;
  overflow: hidden; }

.steps-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  transition: transform 0.5s ease-in-out 0.25s, height 0.5s ease-in-out 0.25s; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .steps-content {
      transition: transform 0.5s ease-in-out, height 0.5s ease-in-out; } }

.step {
  display: block;
  width: 100%;
  opacity: 0.7;
  transform: scale(0.9);
  transition: all 0.25s ease-in; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .step {
      transform: none;
      transition: none; } }

.step-active {
  display: block;
  opacity: 1;
  transform: scale(1);
  transition-timing-function: ease-out;
  transition-delay: 0.75s; }

.steps-navigation {
  margin: 1em 0; }

.step-next {
  float: right; }

.step-prev {
  float: left; }
