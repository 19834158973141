.collector-pricing-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 0.8em;
  width: 100%;
  border-bottom: thin solid gray; }

.collector-pricing-row div {
  padding: 10px; }

.collector-pricing-row div:nth-child(1) {
  width: 20%; }

.collector-pricing-row div:nth-child(2) {
  width: 20%; }

.collector-pricing-row div:nth-child(3) {
  width: 10%; }

.collector-pricing-row div:nth-child(4) {
  width: 10%; }

.collector-pricing-row div:nth-child(5) {
  width: 10%; }

.collector-pricing-row div:nth-child(6) {
  width: 10%; }

.collector-pricing-row div:nth-child(7) {
  width: 10%; }

.collector-pricing-row div:nth-child(8) {
  width: 10%; }

.collector-pricing-column:first-child {
  border-left: thin solid gray; }

.collector-pricing-column {
  border-right: thin solid gray; }
