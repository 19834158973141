.FlexParent {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 0 1em 0;
  padding: 0; }

.width50 {
  width: 50%; }

.width25 {
  width: 24%; }

.width20 {
  width: 19%; }

.width10 {
  width: 10%; }

div.ContentDiv {
  margin-top: 1em;
  margin-bottom: 1em; }

div.DisciplinePrices {
  margin-top: 1em; }

.disabled div {
  text-decoration: line-through; }

.DisciplineHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.DisciplineIcon {
  width: 50px;
  height: 50px; }

.DisciplineHeader {
  flex-grow: 1; }

.ContentWrapper {
  margin-top: 2em;
  margin-bottom: 2em; }

.FrameAgreementDetailBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 1em; }

.FrameAgreementDetailBlock div:first-child {
  font-weight: bold;
  font-size: 1.1em; }

.FrameAgreementDetailBlock div {
  width: 49%;
  margin-top: 0.5em;
  margin-bottom: 0.5em; }

div.ValueRow {
  margin-top: 1em; }

div.CustomOptionContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center; }

div.CustomOptionChild {
  width: 20%; }

div.CustomOptionContainer div:first-child {
  width: 40%; }

div.CustomOptionDelete {
  width: 10%; }

.agreement-content-list-row {
  width: 100%;
  text-align: left; }

.CheckboxContainer div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row; }

.CheckboxContainer div div.radio {
  margin-right: 1em; }

.sensor-form-wrapper select {
  width: 100%; }

.sensor-discipline-selection .FlexChild {
  margin-right: 0.5em; }

div.service-car-selections h2 {
  margin-top: 1em;
  margin-bottom: 0; }
