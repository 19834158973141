.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 12em;
  z-index: 100;
  margin-top: 1em;
  margin-bottom: 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e3e3e3;
  background-color: #f1f1f1;
  border-radius: 0.2em;
  font-size: 1rem;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  transform: scale(1, 1);
  transform-origin: right top;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.27, 0.17, 0.43, 1); }

.dropdown-menu:before {
  content: '';
  position: absolute;
  top: -1em;
  right: 1em;
  border: 0.5em solid transparent;
  border-bottom-color: #f1f1f1;
  z-index: 10; }

.dropdown-menu:after {
  content: '';
  position: absolute;
  top: -1em;
  right: 1em;
  margin-top: -1px;
  border: 0.5em solid transparent;
  border-bottom-color: #e3e3e3;
  z-index: 9; }

.dropdown-menu.is-hidden {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.85, 0); }

.dropdown-item {
  display: block;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875em;
  transition: all 0.15s; }

.dropdown-item:hover {
  background-color: #eaeaea;
  opacity: 1; }

.dropdown-title:first-child,
.dropdown-item:first-child {
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em; }

.dropdown-title:last-child,
.dropdown-item:last-child {
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em; }

.dropdown-item,
.dropdown-item a,
.dropdown-item button {
  color: #1a447b !important; }

.dropdown-title {
  color: #828282;
  font-weight: bold;
  font-size: 0.75em;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1rem; }

.dropdown-item,
.dropdown-title {
  padding: 0.5rem 0.75rem; }

.dropdown-title:not(:last-child),
.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e3e3e3; }
