.client-net-loading {
    top: 50%;
    left: 50%;
    width: 20em;
    height: 10em;
    margin-top: -5em;
    margin-left: -10em;
    position: absolute;
    border-radius: 0.125em;
}

.client-net-loading .bottom-left-corner {
    width: 3em;
    height: 3em;
    left: -0.125em;
    bottom: -0.125em;
    position: absolute;
    border-radius: 0.125em;
    background-color: #002d6e;
    animation-duration: 1120ms;
    animation-iteration-count: infinite;
    animation-name: loading-bottom-left-corner;
}

.client-net-loading .top-right-corner {
    width: 3em;
    height: 3em;
    top: -0.125em;
    right: -0.125em;
    position: absolute;
    border-radius: 0.125em;
    background-color: #002d6e;
    animation-duration: 1120ms;
    animation-iteration-count: infinite;
    animation-name: loading-top-right-corner;
}

.client-net-loading .loading-text {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    line-height: 10em;
    position: absolute;
    text-align: center;
    background-size: 50%;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/caverion_logo_blue.svg');
}

@keyframes loading-top-right-corner {
    0%      {   width: 3em;         height: 3em;        top: -0.125em;  right: -0.125em;  }
    12.5%   {   width: 0.125em;     height: 6em;        top: -0.125em;  right: -0.125em;  }
    25%     {   width: 0.125em;     height: 6em;        top: 4.125em;   right: -0.125em;  }
    37.5%   {   width: 3em;         height: 3em;        top: 7.125em;   right: -0.125em;  }
    50%     {   width: 6em;         height: 0.125em;    top: 10.125em;  right: -0.125em;  }
    62.5%   {   width: 3em;         height: 3em;        top: 7.125em;   right: 17.125em;  }
    75%     {   width: 0.125em;     height: 6em;        top: -0.125em;  right: 20.125em;  }
    87.5%   {   width: 3em;         height: 3em;        top: -0.125em;  right: 17.125em;  }
    100%    {   width: 6em;         height: 0.125em;    top: -0.125em;  right: -0.125em;  }
}

@keyframes loading-bottom-left-corner {
    0%      {   width: 3em;         height: 3em;        bottom: -0.125em;   left: -0.125em;  }
    12.5%   {   width: 0.125em;     height: 6em;        bottom: -0.125em;   left: -0.125em;  }
    25%     {   width: 0.125em;     height: 6em;        bottom: 4.125em;    left: -0.125em;  }
    37.5%   {   width: 3em;         height: 3em;        bottom: 7.125em;    left: -0.125em;  }
    50%     {   width: 6em;         height: 0.125em;    bottom: 10.125em;   left: -0.125em;  }
    62.5%   {   width: 3em;         height: 3em;        bottom: 7.125em;    left: 17.125em;  }
    75%     {   width: 0.125em;     height: 6em;        bottom: -0.125em;   left: 20.125em;  }
    87.5%   {   width: 3em;         height: 3em;        bottom: -0.125em;   left: 17.125em;  }
    100%    {   width: 6em;         height: 0.125em;    bottom: -0.125em;   left: -0.125em;  }
}