.RichEditor-editor ul li {
    list-style-type: disc;
    margin-left: 20px;
}

.RichEditor-editor h1, DraftEditor-editorContainer h1 {
    font-size: 140%;
}

.RichEditor-editor h2, DraftEditor-editorContainer h2 {
    font-size: 130%;
}

.RichEditor-editor h3, DraftEditor-editorContainer h3 {
    font-size: 120%;
}

.RichEditor-editor h4, DraftEditor-editorContainer h4 {
    font-size: 110%;
}