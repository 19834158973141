.breadcrumb-container {
  position: fixed;
  top: 4em;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  border-bottom: 1px solid #e6e6e6;
  z-index: 200; }

.breadcrumb-list {
  margin: 0;
  padding: 0; }

.breadcrumb-details-link {
  float: right;
  width: 14em;
  border-left: 1px solid #e6e6e6;
  text-align: center;
  font-size: 0.875em;
  line-height: 3rem; }

.breadcrumb-details-link a {
  color: #002f6c; }

.breadcrumb-locked {
  margin-right: 0.5em; }
