.bundles {
  display: block; }

.bundle {
  margin-bottom: 0.75em;
  border: 2px solid #002D6E; }
  .collector-new-bundle-add-active .bundle:last-child {
    animation-name: bundleIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; }

.bundle-special {
  border-color: #002D6E; }

.bundle-title-detail {
  font-size: 0.875em;
  font-weight: normal;
  margin-left: auto;
  text-align: right; }
  .bundle-title-detail:not(:last-child) {
    margin-right: 1.25em; }

.bundle-title-detail-label {
  font-weight: bold;
  margin-bottom: 0.25em; }

.bundle-header {
  padding: 0.75em;
  background-color: #002D6E;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center; }
  .bundle-special .bundle-header {
    background-color: #002D6E; }

.bundle-content {
  padding: 0.75em 0.75em 0;
  background-color: #E6E6E6; }

.bundle-item-list {
  display: flex;
  flex-flow: row wrap; }

.bundle-item {
  margin-right: 0.75em;
  margin-bottom: 0.75em;
  padding: 0.375em 0.75em;
  border: 2px solid #002D6E;
  background-color: #FFFFFF;
  color: #002D6E;
  font-weight: bold;
  cursor: pointer; }
  .bundle-item.is-completed {
    border-color: #61AB09;
    background-color: #FFFFFF;
    color: #61AB09; }
  .bundle-item:hover {
    border-color: #002D6E;
    background-color: #002D6E;
    color: #FFFFFF; }

.bundle-cost {
  padding: 0.75em 0.75em 0; }

.bundle-cost-title {
  font-weight: bold;
  color: #73787D;
  margin-bottom: 1rem; }

.bundle-cost-actions {
  float: right; }

.bundle-cost-action {
  display: inline-block;
  margin-left: 0.75em; }

.bundle-cost-edit {
  color: #00B4E1;
  font-size: 0.875em; }

.bundle-cost-hidden {
  display: none; }

.bundle-cost-details {
  display: flex;
  flex-flow: row wrap; }

.bundle-cost-detail {
  padding-right: 0.75em;
  padding-bottom: 0.75em;
  width: calc(100% / 3); }
  .bundle-cost-detail:nth-child(3) {
    margin-right: 0; }

.bundle-cost-name {
  font-weight: bold;
  margin-bottom: 0.375em; }

.bundle-cost-value-inner {
  margin-right: 0.75em; }

.bundle-edit-sensor {
  margin-left: auto; }

.bundle-cost-summary-content {
  border-top: 1px solid #E6E6E6;
  padding: 0 0.75em;
  transition: transform 0.5s ease-in-out; }
  .bundle-cost-summary-content.is-hidden {
    display: none; }
  .bundle-cost-summary-content .cost-summary {
    margin: 0.75em 0; }

.bundle-cost-summary-toggle {
  display: block;
  position: relative;
  color: #73787D;
  text-align: center;
  font-size: 0.875em;
  padding-bottom: 2em;
  background: linear-gradient(to bottom, rgba(0, 45, 110, 0) 0%, rgba(0, 45, 110, 0) 50%, rgba(0, 45, 110, 0.15) 100%);
  transition: color 0.3s ease-in-out 0.15s;
  will-change: color; }
  .bundle-cost-summary-toggle.is-hidden {
    display: none; }
  .bundle-cost-summary-toggle:hover {
    color: #002D6E; }
    .bundle-cost-summary-toggle:hover .bundle-cost-summary-arrow {
      transform: translate(-50%, -1%); }

.bundle-cost-summary-arrow {
  position: absolute;
  left: 50%;
  bottom: 0.5rem;
  will-change: transform;
  transform: translate(-50%, -25%);
  transition: transform 0.3s ease-in-out; }

.bundle-totals {
  margin-top: 3em; }

@keyframes bundleIn {
  from {
    opacity: 0;
    transform: scale(0.95); } }
