.badge {
  padding: 0.25em 0.5em;
  font-size: 0.875rem;
  border-radius: 0.25em;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }

.badge-draft {
  background-color: #00B4E1;
  color: #FFFFFF; }

.badge-primary {
  background-color: #00B4E1;
  color: #FFFFFF; }

.badge-rejected {
  background-color: #E20404;
  color: #FFFFFF; }

.badge-approved {
  background-color: #002D6E;
  color: #FFFFFF; }

.badge-sent-for-approval {
  background-color: #002D6E;
  color: #FFFFFF; }
