.calendar-matrix {
  display: table;
  width: 100%;
  line-height: 1.5;
  font-size: 0.75em;
  border-spacing: 1px; }

.calendar-matrix-row {
  display: table-row; }

.calendar-matrix-col {
  display: table-cell;
  padding: 0.5em 0.25em; }

.calendar-matrix-header {
  text-align: center; }

.calendar-matrix-item-title {
  font-weight: bold; }

.calendar-matrix-item-checked {
  background: #002d6e; }

.calendar-matrix-item-unchecked {
  background: #e6e6e6; }
