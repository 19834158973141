.vertical-navigation-bar {
  position: fixed;
  left: 0;
  top: 9.5em;
  width: 16em;
  padding: 0;
  margin: 0; }

.vertical-navigation-subitem {
  font-size: 0.875em; }
  .vertical-navigation-subitem .fa {
    margin-right: 0.5em; }
