.agreement-contents-row-title {
  margin-bottom: 1rem;
  font-weight: bold; }

.agreement-contents-row {
  line-height: 1.25;
  margin: 2em 0;
  min-height: 60px; }

.agreement-contents-row-content p {
  margin: 1em 0; }

.agreement-contents-edit-content-buttons {
  padding-top: 1em;
  overflow: hidden; }

.agreement-content-edit-content-buttons-primary {
  float: right; }

.agreement-content-edit-content-buttons-secondary {
  float: left; }

.agreement-original-content {
  padding: 1em;
  font-size: 0.9em; }
