.accordion-content {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  border-left: 0.25rem solid #E6E6E6; }

.accordion-title {
  position: relative;
  display: flex;
  padding: 1rem;
  background-color: #E6E6E6;
  color: #002D6E;
  margin-bottom: 0.5rem;
  font-weight: bold;
  transition: all 0.15s ease; }
  .accordion-title::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 10px;
    height: 5px;
    border-width: 0 6px 6px;
    border-style: solid;
    border-color: #002D6E transparent; }
  .accordion-title:hover {
    background-color: #002D6E;
    color: #FFFFFF; }
    .accordion-title:hover::after {
      border-color: #FFFFFF transparent; }
  .accordion--closed .accordion-title::after {
    border-width: 6px 6px 0; }
