/**
 * Base styles
 */
.ct-button {
  display: inline-block;
  padding: 0.25em 0.75em;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25em;
  vertical-align: middle;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.15s ease, color 0.15s ease, background-color 0.15s ease; }

/**
 * Color variants
 */
.ct-button-primary,
.ct-button-cancel {
  border-color: #FF911E;
  background-color: #FF911E;
  color: #FFFFFF; }
  .ct-button-primary:focus,
  .ct-button-cancel:focus {
    border-color: #ff9a30;
    background-color: #ff9a30; }
  .ct-button-primary:hover, .ct-button-primary:active, .ct-button-primary.active,
  .ct-button-cancel:hover,
  .ct-button-cancel:active,
  .ct-button-cancel.active {
    border-color: #ff9d38;
    background-color: #ff9d38;
    opacity: 1; }

.ct-button-secondary {
  border-color: #00B4E1;
  background-color: #00B4E1;
  color: #FFFFFF; }
  .ct-button-secondary:focus {
    border-color: #00c2f3;
    background-color: #00c2f3; }
  .ct-button-secondary:hover, .ct-button-secondary:active, .ct-button-secondary.active {
    border-color: #00c8fb;
    background-color: #00c8fb;
    opacity: 1; }

.ct-button-add {
  border-color: #002D6E;
  background-color: #002D6E;
  color: #FFFFFF; }
  .ct-button-add:focus {
    border-color: #003480;
    background-color: #003480; }
  .ct-button-add:hover, .ct-button-add:active, .ct-button-add.active {
    border-color: #003788;
    background-color: #003788;
    opacity: 1; }

.ct-button-delete {
  border-color: #FF5A5F;
  background-color: #FF5A5F;
  color: #FFFFFF; }
  .ct-button-delete:focus {
    border-color: #ff6c70;
    background-color: #ff6c70; }
  .ct-button-delete:hover, .ct-button-delete:active, .ct-button-delete.active {
    border-color: #ff7478;
    background-color: #ff7478;
    opacity: 1; }

.ct-button-disabled {
  border-color: #E6E6E6;
  background-color: #E6E6E6;
  color: #FFFFFF; }
  .ct-button-disabled:focus {
    border-color: #efefef;
    background-color: #efefef; }
  .ct-button-disabled:hover, .ct-button-disabled:active, .ct-button-disabled.active {
    border-color: #f3f3f3;
    background-color: #f3f3f3;
    opacity: 1; }

.ct-button-link {
  border-color: transparent;
  background-color: transparent;
  color: #002D6E; }
  .ct-button-link:focus {
    border-color: rgba(9, 9, 9, 0);
    background-color: rgba(9, 9, 9, 0); }
  .ct-button-link:hover, .ct-button-link:active, .ct-button-link.active {
    border-color: rgba(13, 13, 13, 0);
    background-color: rgba(13, 13, 13, 0);
    opacity: 1; }

.ct-button-page-title {
  border-color: transparent;
  background-color: transparent;
  color: #002D6E;
  font-size: inherit;
  font-family: 'Sanchez-Regular', serif !important;
  font-weight: normal !important;
  padding: 0; }
  .ct-button-page-title:focus {
    border-color: rgba(9, 9, 9, 0);
    background-color: rgba(9, 9, 9, 0); }
  .ct-button-page-title:hover, .ct-button-page-title:active, .ct-button-page-title.active {
    border-color: rgba(13, 13, 13, 0);
    background-color: rgba(13, 13, 13, 0);
    opacity: 1; }
  .ct-button-page-title.ct-button-dropdown {
    padding-right: 1.75em; }
    .ct-button-page-title.ct-button-dropdown:after {
      right: 0.3em; }

/**
 * Sizes
 */
.ct-button-size-xsmall {
  padding: 0.5em 1em;
  font-size: 0.625em; }

.ct-button-size-small {
  padding: 0.5em 1em;
  font-size: 0.75em; }

.ct-button-size-large {
  font-size: 1.25em; }

/**
 * Dropdown
 */
.ct-button-dropdown {
  position: relative;
  padding-right: 2.5em; }
  .ct-button-dropdown:after {
    content: '';
    position: absolute;
    right: 1em;
    margin-right: 0.25em;
    top: 50%;
    margin-top: -0.15em;
    border: 0.3em solid transparent;
    border-top-color: #fff; }
  .ct-button-dropdown.ct-button-page-title:after, .ct-button-dropdown.ct-button-link:after {
    border-top-color: #002D6E; }
