.tabs {
  margin: 1em 0; }

.tabs-labels-horizontal {
  display: flex;
  border-bottom: 1px solid #002D6E; }
  .tabs-labels-horizontal .tabs-label {
    padding: 1em 2em;
    margin-bottom: -1px;
    color: #002D6E; }
    .tabs-labels-horizontal .tabs-label:hover {
      color: #FF911E;
      opacity: 1; }
    .tabs-labels-horizontal .tabs-label.active {
      border-style: solid;
      border-color: #002D6E;
      border-bottom-color: #FFFFFF;
      border-width: 1px; }

.tabs-labels-vertical {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 9.5rem;
  width: 16rem; }
  .tabs-labels-vertical .tabs-label {
    display: block;
    position: relative;
    margin: 0;
    padding: 0.75em;
    border-bottom: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    background-color: #FFFFFF;
    color: #002D6E;
    line-height: 1; }
    .tabs-labels-vertical .tabs-label:first-child {
      border-top: 1px solid #E6E6E6; }
    .tabs-labels-vertical .tabs-label.active {
      background-color: #002D6E;
      color: #FFFFFF; }
      .tabs-labels-vertical .tabs-label.active:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -2.5em;
        width: 2.5em;
        border-width: 1.25em;
        border-style: solid;
        border-color: transparent;
        border-left-color: #002D6E; }
