.loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  transform: translateZ(0);
  transition: opacity 0.2s ease-in; }

.loader-leave.loader-leave-active,
.loader-enter {
  opacity: 0.01; }

.loader-leave,
.loader-enter.loader-enter-active {
  opacity: 1; }

.loader-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 45, 110, 0.8); }

.loader-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  border-radius: 0.5em;
  width: 16em;
  height: 16em;
  padding: 2em;
  color: #fff;
  transition: opacity 0.15s ease, transform 0.15s ease; }

.loader-leave.loader-leave-active .loader-wrapper,
.loader-enter .loader-wrapper {
  opacity: 0;
  transform: scale(0.85); }

.loader-leave .loader-wrapper,
.loader-enter.loader-enter-active .loader-wrapper {
  opacity: 1;
  transform: scale(1); }

.loader-icon {
  font-size: 5em; }

.loader-title {
  font-size: 1.25em;
  font-weight: normal;
  font-family: 'Sanchez-Regular', serif;
  text-transform: uppercase;
  color: #eee;
  transform: translateZ(0);
  animation-name: fadeInPartly;
  animation-duration: 1.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; }

.loader-title::first-letter {
  font-size: 1.25em; }

.loader-dot {
  display: inline-block;
  animation-name: loaderFadePulse;
  animation-duration: 0.9s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

.loader-dot:nth-child(2) {
  animation-delay: 0.15s; }

.loader-dot:nth-child(3) {
  animation-delay: 0.3s; }

@keyframes loaderFadePulse {
  0% {
    opacity: 1; }
  20% {
    opacity: 0.5; }
  40% {
    opacity: 1; } }

@keyframes loaderFadeInPartly {
  from {
    opacity: 0.5; }
  to {
    opacity: 1; } }
