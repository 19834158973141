.agreement-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 0.75em 1em;
  background-color: #002D6E;
  color: #FFFFFF; }
  .agreement-list-item-primary {
    background-color: #002D6E;
    color: #FFFFFF; }
  .agreement-list-item-secondary {
    background-color: #E6E6E6;
    color: #002D6E; }

.agreement-list-item-link {
  color: #FFFFFF; }
  .agreement-list-item-secondary .agreement-list-item-link {
    color: #002D6E; }

.agreement-list-item-action-icon {
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  line-height: 1.5em;
  vertical-align: middle;
  text-align: center;
  width: 1.5em; }
  .agreement-list-item-action-icon .fa {
    color: #002D6E; }

.agreement-list-item-title {
  font-family: "Sanchez-Regular", serif;
  font-weight: normal;
  font-size: 1.25em;
  margin-right: 1em; }
  .agreement-list-item-secondary .agreement-list-item-title {
    font-family: Arial, sans-serif;
    font-size: 1em; }

.agreement-list-item-time {
  font-size: 0.8em;
  white-space: nowrap; }

.agreement-list-item-created {
  font-size: 0.8em;
  margin-top: 0.25rem; }

.agreement-list-item-side {
  display: flex; }

.agreement-list-item-actions {
  display: flex;
  align-items: center; }

.agreement-list-item-action {
  position: relative;
  margin-left: 1em;
  cursor: pointer;
  white-space: nowrap; }

.agreement-list-item-action a {
  color: #FFFFFF; }
  .agreement-list-item-primary .agreement-list-item-action a {
    color: #FFFFFF; }
  .agreement-list-item-secondary .agreement-list-item-action a {
    color: #002D6E; }

.agreement-list-item-status {
  padding: 0.25em 0.5em;
  border: 1px solid transparent;
  border-radius: 0.25em;
  background-color: #FFFFFF;
  color: #002D6E;
  font-size: 0.8em; }
  .agreement-list-item-status-draft {
    background-color: #FFFFFF;
    color: #002D6E; }
  .agreement-list-item-status-signed {
    background-color: #002D6E;
    color: #FFFFFF;
    border-color: #FFFFFF; }
  .agreement-list-item-status-waiting {
    background-color: #FF911E;
    color: #FFFFFF; }
