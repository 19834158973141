.operating-hours-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

.detail-row {
  margin-top: 1em; }

.detail-row div {
  width: 50%; }

.operating-hours-row label {
  margin-right: 1em; }

.bolded {
  font-weight: bold; }

.operating-hours-container {
  margin-left: 1em; }
