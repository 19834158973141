.dummy-input-field {
  padding: 1em 0; }

.dummy-input-field__label {
  margin-bottom: 0.5em; }

.dummy-input-field__input {
  padding: 0.5em 2em 0.5em 0.5em;
  font-size: 1em;
  min-height: 1.5em;
  line-height: 1.5;
  border: 1px solid #E6E6E6;
  cursor: not-allowed;
  vertical-align: middle;
  box-sizing: content-box; }
