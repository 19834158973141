.visitplan {
  animation-name: visitplanFadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out; }

/*
.visitplan-matrix {
    animation-name: visitplanSlideDown;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    transform-origin: center top;
}
*/
.visitplan-matrix-wrapper-enter,
.visitplan-matrix-wrapper-leave {
  transform-origin: center top; }

.visitplan-matrix-wrapper-enter {
  opacity: 0.01;
  max-height: 0; }

.visitplan-matrix-wrapper-enter.visitplan-matrix-wrapper-enter-active {
  opacity: 1;
  max-height: 100vh;
  transition: opacity 200ms ease, max-height 600ms linear; }

/*
.visitplan-matrix-wrapper-leave {
    opacity: 1;
    max-height: 100vh;
    transform: scaleY(1);
}

.visitplan-matrix-wrapper-leave.visitplan-matrix-wrapper-leave-active {
    opacity: 0.01;
    max-height: 0;
    transition: opacity 200ms ease, max-height 400ms linear;
}
*/
@keyframes visitplanFadeIn {
  from {
    opacity: 0; } }

@keyframes visitplanSlideDown {
  from {
    opacity: 0.5; } }
