.standard-page {
  display: block;
  max-width: 48em;
  margin: 0 auto;
  padding: 3em 0; }

.standard-page:after {
  content: "";
  display: table;
  clear: both; }

.standard-page.wide {
  max-width: 64em;
  padding: 3em 2em; }

.standard-page.with-breadcrumb {
  padding: 6em 0 3em; }

.standard-page.with-subnavigation {
  padding: 9em 0 3em; }

.standard-page.with-subnavigation.with-breadcrumb {
  padding: 12em 0 3em; }

.standard-page.with-sidenavigation {
  max-width: none;
  padding: 3em 2em 3em 20em; }

.standard-page.with-sidenavigation.with-breadcrumb {
  padding: 6em 2em 3em 20em; }
