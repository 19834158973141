.content {
  font-size: 1em;
  line-height: 1.5;
  font-family: sans-serif;
  /* Let's make sure all's aligned */ }
  .content h1,
  .content .h1 {
    font-size: 1.75em;
    line-height: 1.71428571em;
    margin-top: 0.85714286em;
    margin-bottom: 1.71428572em; }
  .content h2,
  .content .h2 {
    font-size: 1.4375em;
    line-height: 1.04347826em;
    margin-top: 1.04347826em;
    margin-bottom: 1.04347826em; }
  .content h3,
  .content .h3 {
    font-size: 1.1875em;
    line-height: 1.26315789em;
    margin-top: 1.26315789em;
    margin-bottom: 0em; }
  .content h4,
  .content .h4 {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 0em; }
  .content h5,
  .content .h5 {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 0em; }
  .content p,
  .content ul,
  .content ol,
  .content pre,
  .content table,
  .content blockquote {
    margin-top: 0em;
    margin-bottom: 1.5em; }
  .content ul {
    list-style-type: disc;
    padding-left: 1em; }
  .content ol {
    padding-left: 1em; }
  .content ul ul,
  .content ol ol,
  .content ul ol,
  .content ol ul {
    margin-top: 0em;
    margin-bottom: 0em; }
  .content hr,
  .content .hr {
    border: 1px solid;
    margin: -1px 0; }
  .content a,
  .content b,
  .content i,
  .content strong,
  .content em,
  .content small,
  .content code {
    line-height: 0; }
  .content sub,
  .content sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  .content sup {
    top: -0.5em; }
  .content sub {
    bottom: -0.25em; }
