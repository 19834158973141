.breadcrumb-item {
  position: relative;
  font-size: 0.875em;
  display: inline-block;
  line-height: 3rem;
  margin: 0 1em; }

.breadcrumb-item a {
  color: #002f6c; }

.breadcrumb-item:not(:last-child):after {
  position: absolute;
  content: '/';
  top: 0;
  right: -1em;
  color: #e6e6e6; }
