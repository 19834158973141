.cost-summary {
  margin: 1em 0; }

.cost-summary-title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1rem; }

.cost-summary-row {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 0.5rem; }

.cost-summary-row-invalid {
  color: #73787D; }
  .cost-summary-row-invalid:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E6E6E6; }

.cost-summary-row-secondary {
  color: #73787D;
  font-size: 0.8em; }

.cost-summary-label {
  flex: 1 1 auto; }

.cost-summary-value {
  text-transform: uppercase; }

.cost-summary-totals {
  margin-top: 0.5rem;
  font-size: 1em;
  font-weight: bold; }

.cost-summary-h4 {
  margin-bottom: 0.5em;
  margin-top: 2em; }
