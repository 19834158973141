.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  transform: translateZ(0); }

.dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 45, 110, 0.3);
  animation-name: dialog-fadein;
  animation-duration: 0.25s;
  animation-timing-function: ease-out; }

.dialog-window {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-top: 3px solid #FF911E;
  border-radius: 1px;
  width: 30em;
  max-height: 90vh;
  overflow-y: auto; }
  .dialog-large .dialog-window {
    width: 42em; }
  .dialog-size-xl .dialog-window {
    width: 48em; }

.dialog-body {
  padding: 2rem 2rem 0;
  margin-bottom: 2em;
  font-size: 1.25em;
  line-height: 1.5;
  text-align: left !important;
  color: #000000; }
  .dialog-size-xl .dialog-body {
    font-size: inherit;
    line-height: inherit; }

.dialog-actions {
  margin-top: 2em;
  padding: 0 2rem 2rem;
  text-align: right; }

.dialog-action {
  cursor: pointer;
  opacity: 1; }

.dialog-action:not(:last-child) {
  margin-right: 1em; }

@keyframes dialog-fadein {
  from {
    opacity: 0.3; } }
