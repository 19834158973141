.AirHandlingSystemTypes_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.AirHandlingSystemType_image {
  width: 50%; }

.FlexContainer {
  display: flex;
  margin-top: 1em;
  justify-content: space-between; }

.FlexChild {
  width: 48%; }

.CollectorContainer {
  border-bottom: thin solid gray;
  margin-bottom: 1em;
  margin-top: 1em; }

.FormContainer {
  margin-top: 1em;
  margin-bottom: 0.5em; }

.SensorFormHeader {
  margin-top: 2em;
  border-bottom: thin solid gray; }

.KitpackSensor div {
  margin-top: 0.5em;
  margin-bottom: 0.5em; }

.displayInline {
  display: inline;
  margin-left: 2em; }

.displayInlineBlock {
  display: inline-block; }

h3.header {
  margin-top: 1em;
  margin-bottom: 0.5em; }

div.CheckboxContainer {
  margin-top: 0.5em; }

div.SensorCard div {
  margin-top: 0.5em; }

div.add-bundle-selection-error {
  font-size: 0.9em;
  margin-bottom: 1em; }

.collectors-item {
  margin-bottom: 0.5em; }

.collector-delete {
  float: right; }

.collector-header {
  margin-bottom: 1rem; }

.collector-add-bundle-button {
  display: block;
  padding: 1em;
  background-color: #FF911E;
  border-radius: 0.25em;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 1em; }
