.attachment-no-description {
  color: #73787D; }

.attachment-actions {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 1em;
  width: 100%; }

.attachment-button-edit {
  padding: 0.25em 0.75em;
  background-color: #FF911E;
  color: #FFFFFF;
  border-radius: 0.25em;
  margin-left: 0.5em;
  line-height: 1.5;
  font-size: 1rem; }
  .attachment-button-edit .fa {
    vertical-align: middle; }

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.inputfile + label {
  font-size: 1em;
  font-weight: 400;
  color: #FFFFFF;
  display: inline-block;
  margin-top: 53px; }
