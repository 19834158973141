.agreement-detail-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 1em; }

.agreement-detail-block div:first-child {
  font-weight: bold;
  font-size: 1em; }

.agreement-detail-block div {
  width: 49%;
  margin-top: 0.5em;
  margin-bottom: 0.5em; }
