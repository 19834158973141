.agreement-delete-title {
  font-size: 1.25em;
  margin-bottom: 1.5em; }

.agreement-delete-row {
  display: flex;
  flex-flow: column nowrap;
  margin: 1em 0; }

.agreement-delete-label {
  font-weight: bold;
  margin-bottom: 0.25em; }

.agreement-delete-item {
  margin: 0.25em 0; }
  .agreement-delete-item ul {
    padding-left: 1em; }
  .agreement-delete-item li {
    list-style: disc outside;
    margin: 0.25em; }
