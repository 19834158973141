.cards {
  margin: 2em 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  text-align: center; }
  .cards::after {
    content: '';
    flex: auto; }
  .cards > *:not(:nth-child(3n)) {
    margin-right: 1em; }

.card {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% / 3 - 2em / 3);
  min-height: 12em;
  margin-bottom: 1em;
  color: #002D6E;
  border-radius: 2px;
  overflow: hidden;
  border-bottom: 1px solid #002D6E; }

.card-title {
  padding: 1em;
  position: relative;
  font-size: 1em;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #002D6E; }

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  padding: 1em;
  color: #002D6E;
  font-size: 14px;
  border-style: solid;
  border-color: #E6E6E6;
  border-width: 0 1px; }
  .card-body:last-child {
    border-bottom-width: 1px; }

.card-body-icon-red {
  color: #FF5A5F; }

.card-body-icon-green {
  color: #61AB09; }

.card-ctas {
  padding: 0.5em 0.5em 1em;
  border-style: solid;
  border-color: #E6E6E6;
  border-width: 0 1px 1px; }

.card-cta {
  display: inline-block;
  font-size: 0.875em;
  background-color: #FF911E;
  padding: 0.75em 1em;
  color: #FFFFFF;
  border-radius: 0.25em; }

.action-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #E6E6E6;
  border-radius: 0.25em;
  color: #73787D;
  padding: 1em;
  min-height: 12em;
  width: calc(100% / 3 - 2em / 3);
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.15s ease; }
  .action-card:hover {
    color: #8d9296; }
