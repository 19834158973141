.agreement-attachments {
  margin: 1em 0 2em; }

.agreement-attachments-fake-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #E6E6E6;
  border-radius: 0.25em;
  color: #73787D;
  padding: 1em;
  min-height: 12em;
  width: calc(100% / 3 - 2em / 3);
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.15s ease; }
  .agreement-attachments-fake-card:hover {
    color: #8d9296; }

.attachmentRow {
  width: 40%;
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: top; }

.attachmentRowUpload {
  width: 5%;
  display: inline-block; }

.inputfield {
  padding: 0.5em;
  box-shadow: none;
  -webkit-appearance: none;
  font-size: 1em;
  line-height: 1.5em;
  border: 1px solid #002D6E;
  width: 100%; }
