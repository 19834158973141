.agreement-signatures {
  margin: 1em 0 2em; }

.agreement-signatures-add {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #E6E6E6;
  border-radius: 0.25em;
  color: #73787D;
  padding: 0.5em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.15s ease;
  text-align: center;
  height: 120px; }

.agreement-signatures-add:hover {
  color: #8d9296; }

.agreement-signatures-columns {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }

.agreement-signatures-list {
  width: 48%;
  margin-top: 1em; }

.agreement-signatures-list-subheader {
  margin: 1em;
  font-weight: bold; }

.agreement-signatures-list .item {
  width: 100%;
  border: 1px dotted #73787D;
  padding: 1em;
  margin-bottom: 1em;
  display: flex;
  height: 120px; }

.agreement-signatures-list .item .line2 {
  padding-top: 0.25em; }

.signature-button {
  font-size: 1em;
  margin-left: 0.5em;
  background: transparent;
  border: 0;
  padding: 0.5em 0.75em;
  transition: opacity 0.15s;
  border-radius: 0.25em;
  color: #fff;
  cursor: pointer; }
  .signature-button-delete {
    background-color: #FF5A5F; }
  .signature-button-add {
    background-color: #FF911E; }
  .signature-button:hover {
    opacity: 0.8; }

.signature-edit-container {
  margin-left: auto; }

.signature-line {
  border-bottom: 1px solid #73787D;
  width: 100%;
  min-width: 200px;
  margin-top: 1em;
  margin-bottom: 0.5em; }

.signature-line-label {
  margin: 0.75em 0; }

.signature-row-checkbox {
  width: 30px;
  height: 30px; }
